<template>
  <div class="success">
    <div class="success-content">
      <div class="title">
        <img
          src="https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/memberBenefits/fail_icon.png"
          class="icon"
        />
        <span>{{active.title}}</span>
      </div>
      <div class="text">{{active.msg}}</div>
      <button class="submit" @click="close">确定</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Object,
      default: () => ({
        msg: "",
		    title: '领取失败'
      }),
    },
  },
  methods: {
    close() {
      this.$emit("update:visibility", false);
      // this.$emit("update:active", {});
    },
  },
};
</script>

<style lang="scss" scoped>
.success {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);

  &-content {
    position: absolute;
    top: 50%;
    left: 46px;
    right: 46px;
    height: 345px;
    transform: translateY(-50%);
    background: #ffffff;
    border-radius: 18px;
    text-align: center;

    .title {
      padding: 42px 0 21px;

      .icon {
        width: 40px;
        margin-right: 12px;
      }

      .icon,
      span {
        vertical-align: middle;
      }
    }

    .text {
      font-size: 28px;
      font-weight: 400;
      padding: 0 89px 0;
      color: #333333;
    }

    .submit {
      margin-top: 46px;
      width: 480px;
      height: 76px;
      line-height: 76px;
      font-size: 32px;
      font-weight: 400;
      color: #ffffff;
      background: linear-gradient(335deg, #ff7c00 0%, #ff5000 100%);
      border-radius: 44px;
      border: none;
    }
  }
}
</style>
