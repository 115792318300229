<template>
  <div class="opened">
    <base-navigation-bar
      title="随心会员"
      :isLeft="true"
      :isRight="true"
      @handleJump="handleJump"
      @handleBack="handleBack"
    />
    <div class="top">
      <div class="card">
        <div class="box">
          <div class="left">
            <img :src="getHeadImg()" class="user" />
          </div>
          <div class="right">{{ strPhone }}</div>
        </div>
        <div class="tag">随心会员将于{{ strTime }}自动续费</div>
      </div>
    </div>
    <div class="content">
      <!-- {{ memberUser }} -->
      <div class="title">
        <img
          src="https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/memberBenefits/border1.png"
          class="img"
        />
        <div class="text_">当前可领取权益</div>
        <img
          src="https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/memberBenefits/border2.png"
          class="img"
        />
      </div>
      <div class="line">
        <div
          :class="[
            'line-item',
            { opacity: !!received },
            { active: received == item.item_name },
          ]"
          v-for="(item, index) in list"
          :key="index"
          @click="showConfirm(item)"
        >
          <div class="img">
            <img :src="item.item_icon" class="icon" />
          </div>
          <div class="bottom">
            <div class="name">{{ item.item_name }}</div>
            <div class="text">剩余{{ item.item_count }}份</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 权益领取弹窗 -->
    <v-dialog-confirm
      v-if="confirm.visibility"
      :visibility.sync="confirm.visibility"
      :active.sync="confirm.active"
      @handleSubmit="handleSubmit"
    />
    <!-- 领取成功 -->
    <v-dialog-success v-if="isSuccess" :visibility.sync="isSuccess" />
    <v-dialog-fail
      v-if="fail.visibility"
      :visibility.sync="fail.visibility"
      :active.sync="fail.active"
    />
    <base-loading v-if="loading" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { equitylist, claimBenefits } from "@/api/tao.js";

import BaseLoading from "@/components/BaseLoading/index";
import BaseNavigationBar from "@/components/BaseNavigationBar/BaseNavigationBar.vue";
import VDialogConfirm from "../dialog/confirm.vue";
import VDialogSuccess from "../dialog/success.vue";
import VDialogFail from "../dialog/fail.vue";

export default {
  components: {
    VDialogConfirm,
    VDialogSuccess,
    VDialogFail,
    BaseLoading,
    BaseNavigationBar,
  },
  data() {
    return {
      phone: "",
      loading: false,
      // 已领取的权益,
      received: "",
      list: [],
      // 权益领取弹窗
      confirm: {
        visibility: false,
        active: {},
      },
      fail: {
        visibility: false,
        active: {},
      },
      isSuccess: false,
    };
  },
  computed: {
    ...mapGetters([
      "member_head_img",
      "member_user_info",
      "member_phone_number",
      "member_token",
    ]),
    strPhone() {
      const reg = /(\d{3})\d*(\d{4})/;
      let str = this.member_phone_number;
      str = str.replace(reg, "$1****$2");
      return str;
    },
    strTime() {
      return (
        (this.member_user_info.vip_renewal_time &&
          this.member_user_info.vip_renewal_time[0].substr(0, 10)) ||
        ""
      );
    },
  },
  created() {
    this["memberBenefits/getUserInfo"]();
    this.getList();
  },
  watch: {
    member_user_info(value) {
      this.received =  value.exchanged_items['视听会员'] && value.exchanged_items["视听会员"][0] || "";
    },
  },
  methods: {
    ...mapActions(["memberBenefits/getUserInfo"]),
    async getList() {
      this.list = [];
      const {
        code = 0,
        item = [],
        message = "",
      } = await equitylist({ item_require: "视听会员" });
      if (code == 0) {
        this.list = item;
      } else {
       this.fail = {
          visibility: true,
          active: {
            title: "失败",
            msg: message,
          },
        };
      }
    },
    getHeadImg() {
      if (!!this.member_head_img) return this.member_head_img;
      else
        return "https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/memberBenefits/aqy.png";
    },
    handleJump(item) {
      const { url = "" } = item;
      this.$router.push({ path: url });
    },
    showConfirm(item) {
      if (!!this.received) return;
      this.confirm = {
        visibility: true,
        active: {
          ...item,
          phone: this.member_phone_number,
        },
      };
    },
    handleBack() {
      this.$router.go(-1);
    },
    async handleSubmit(item) {
      const { item_name = "" } = item;
      this.loading = true;
      const res = await claimBenefits(this.member_token, {
        item_name,
        tem_require: "视听会员",
      });
      this.loading = false;
      if (res.code == 0) {
        this.received = item.item_name;
        this.isSuccess = true;
      } else {
        this.fail = {
          visibility: true,
          active: {
            title: "失败",
            msg: res.message,
          },
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.opened {
  background: linear-gradient(148deg, #fef1d9 0%, #fee9be 100%);
  position: relative;
  height: 100%;
  padding: 36px 0 50px;
  ::v-deep .navigation-bar {
    .list {
      box-sizing: border-box;
      transform: translate(-20px, 136px);
    }
  }
  .top {
    padding: 48px 30px 0;

    .card {
      height: 320px;
      background: #feedcf
        url("https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/memberBenefits/card.png")
        center center/contain no-repeat;

      .box {
        display: flex;
        padding: 49px 0 0 31px;

        .left {
          width: 106px;
          height: 105px;
          background-color: #f9eeee;
          border-radius: 60px;
          overflow: hidden;

          .user {
            width: 100%;
          }
        }

        .right {
          font-size: 32px;
          color: #694126;
          line-height: 45px;
          padding: 57px 0 0 24px;
        }
      }

      .tag {
        padding: 34px 0 0 31px;
      }
    }

    .tag {
      font-size: 22px;
      font-weight: 400;
      color: #a57b4d;
    }
  }

  .content {
    min-height: calc(100vh - 368px);
    background: #ffffff;
    border-radius: 24px;
    padding-top: 47px;

    .title {
      height: 56px;
      line-height: 56px;
      background: linear-gradient(
        270deg,
        #ffffff 1%,
        #feefd3 36%,
        #fef3dd 63%,
        #ffffff 99%
      );
      font-size: 30px;
      font-weight: 500;
      color: #694126;
      display: flex;
      align-items: center;
      justify-content: center;

      .text_ {
        padding: 0 20px;
      }

      .img {
        width: 110px;
        height: 8px;
      }
    }

    .line {
      display: flex;
      flex-wrap: wrap;
      padding: 40px 0 100px 28px;
      align-content: flex-start;

      &-item {
        width: 220px;
        min-height: 280px;
        background: url("https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/memberBenefits/qy_card.png")
          center center/cover no-repeat;
        margin-bottom: 20px;
        text-align: center;
        position: relative;
        box-sizing: border-box;

        &:not(:nth-child(3n)) {
          margin-right: 18px;
        }

        &.opacity {
          opacity: 0.5;
        }

        &.active {
          &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background: url("https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/memberBenefits/card_received.png")
              center center/cover no-repeat;
          }
        }

        .img {
          height: 175px;
          display: flex;
          justify-content: center;
          align-items: center;

          .icon {
            width: 120px;
          }
        }

        .bottom {
          min-height: 101px;
          padding: 0 30px;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          justify-content: center;
          align-content: center;

          .name {
            font-size: 26px;
            font-weight: 400;
            color: #333333;
            line-height: 30px;
          }

          .text {
            font-size: 22px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
          }
        }
      }
    }
  }
}
</style>
