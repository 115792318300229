<template>
  <div class="dialog">
    <div class="dialog-content">
      <div class="close" @click="close">×</div>
      <div class="title">权益领取</div>
      <div class="money">
        ￥<span>0.00</span>
      </div>
      <div class="box">
        <div class="line">
          <div class="label">所选权益</div>
          <div class="value">{{ active.item_long_name }}</div>
        </div>
        <div class="line">
          <div class="label">领取号码</div>
          <div class="value">{{ phoneStar(active.phone) }}</div>
        </div>
      </div>
      <van-button class="submit" @click="handleSubmit">立即领取</van-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Object,
      default: () => ({
        raiName: "腾讯视频VIP月卡",
        phone: "17687879000",
        riaSalePrice: "0.00",
      }),
    },
  },
  methods: {
    phoneStar(phone) {
      if (!phone) return "";
      const reg = /(\d{3})\d*(\d{4})/;
      let str = phone;
      str = str.replace(reg, "$1****$2");
      return str;
    },
    close() {
      this.$emit("update:visibility", false);
      this.$emit("update:active", {});
    },
    handleSubmit() {
      this.$emit("handleSubmit", this.active);
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);

  &-content {
    background: #ffffff;
    border-radius: 24px 24px 0 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 50px 140px;

    .close {
      color: #c8c8c8;
      font-weight: 300;
      font-size: 80px;
      position: absolute;
      right: 46px;
      top: 0;
    }

    .title {
      font-size: 34px;
      font-weight: 500;
      color: #333333;
      text-align: center;
      padding: 38px 0 47px 0;
    }

    .money {
      font-size: 40px;
      text-align: center;
      font-weight: 600;
      color: #333333;
      padding-bottom: 42px;

      span {
        font-size: 60px;
      }
    }

    .box {
      .line {
        display: flex;
        padding: 0 50px;
        justify-content: space-between;
        font-size: 30px;
        padding: 29px 0 20px;
        // font-weight: 400;

        &:not(:last-child) {
          border-bottom: 1px solid #e3e3e3;
        }

        .label {
          color: #888888;
        }

        .label,
        .value {
          font-weight: 400;
        }
      }
    }

    .submit {
      height: 88px;
      line-height: 88px;
      background: linear-gradient(335deg, #ff7c00 0%, #ff5000 100%);
      border-radius: 44px;
      font-size: 32px;
      font-weight: 500;
      color: #ffffff;
      margin-top: 40px;
      border: none;
      width: 100%;
    }
  }
}
</style>
